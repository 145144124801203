import type {FC} from 'react';

import type {PageHeadFragment} from '@/modules/foundation/sanity/page-head/query';
import {TextHeadingContainer} from '@/modules/page/sanity/page-head/text-heading-container';
import {HeaderVideoContainer} from '@/modules/vaag/sanity/header-video';
import type {SanityArray} from '@/sanity/lib/types';

interface Props {
	pageHeads: SanityArray<PageHeadFragment>;
}

const PageHead: FC<Props> = ({pageHeads}) => {
	if (!pageHeads.length) {
		return null;
	}

	const activePageHead = pageHeads[0];

	switch (activePageHead._type) {
		case 'textHeading':
			return <TextHeadingContainer data={activePageHead} />;
		case 'headerVideo':
			return <HeaderVideoContainer data={activePageHead} />;
		default:
			return null;
	}
};

export default PageHead;
