import type {FC} from 'react';

import {PortableText} from '@/modules/foundation/sanity/portable-text';
import {
	TextHeading,
	textHeadingPortableTextOverrides,
} from '@/modules/page/components/page-head/text-heading';

import type {TextHeadingSchema} from './schema';

interface Props {
	data: TextHeadingSchema;
}

export const TextHeadingContainer: FC<Props> = ({data}) => {
	const {heading, introduction, textAlignment, bottomLead} = data;

	return (
		<TextHeading heading={heading} textAlignment={textAlignment} bottomLead={bottomLead}>
			{introduction && (
				<PortableText
					blocks={introduction}
					overrideClassNames={textHeadingPortableTextOverrides}
				/>
			)}
		</TextHeading>
	);
};
