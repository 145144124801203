import {PORTABLE_TEXT_FRAGMENT} from '@/modules/foundation/sanity/portable-text/query';

import type {HeaderVideoSchema} from './schema';

export const HEADER_VIDEO_FRAGMENT = `//groq
	...,
	lead[]{
		${PORTABLE_TEXT_FRAGMENT}
	}
`;

export type HeaderVideoFragment = HeaderVideoSchema;
