import {PortableText} from '@/modules/foundation/sanity/portable-text';

import {HeaderVideo} from '../../components/header-video';

import type {HeaderVideoFragment} from './query';

type Props = {
	data: HeaderVideoFragment;
};

export const HeaderVideoContainer = ({data}: Props) => {
	if (!data.id || !data.title) {
		return null;
	}
	return (
		<HeaderVideo
			heading={data.heading ?? ''}
			videoId={data.id}
			videoIdMobile={data.idMobile}
			videoTitle={data.title}
			hiddenHeading={data.hiddenHeading}
			bottomLead={data.bottomLead}
			lead={
				data.lead && (
					<PortableText
						blocks={data.lead}
						// overrideClassNames={textHeadingPortableTextOverrides}
					/>
				)
			}
		/>
	);
};
