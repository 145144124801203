import(/* webpackMode: "eager" */ "/vercel/path0/modules/foundation/components/image/ImageBlock.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/foundation/components/image/ImageFill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/vercel/path0/modules/foundation/components/video/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/page/sanity/frontpage/FrontPageDraft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Desktop"] */ "/vercel/path0/modules/vaag/components/featured-link-card/desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderVideo"] */ "/vercel/path0/modules/vaag/components/header-video/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageExpanding"] */ "/vercel/path0/modules/vaag/components/image-expanding/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoSlider"] */ "/vercel/path0/modules/vaag/components/logo-list-selection/LogoCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/modules/vaag/project/components/project-list-carousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/modules/vaag/project/components/project-list-paginated/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
