import {groq} from 'next-sanity';

import {PAGE_HEADER_TYPE_TEXT_HEADING} from '@/modules/page/sanity/page-head/text-heading-container/constants';
import {
	TEXT_HEADING_FRAGMENT,
	type TextHeadingFragment,
} from '@/modules/page/sanity/page-head/text-heading-container/query';
import {PAGE_HEADER_TYPE_VIDEO} from '@/modules/vaag/sanity/header-video/constants';
import type {HeaderVideoFragment} from '@/modules/vaag/sanity/header-video/query';
import {HEADER_VIDEO_FRAGMENT} from '@/modules/vaag/sanity/header-video/query';

export const PAGE_HEAD_FRAGMENT = groq`
	...,
	_type == "${PAGE_HEADER_TYPE_TEXT_HEADING}" => {
		${TEXT_HEADING_FRAGMENT}
	},
	_type == "${PAGE_HEADER_TYPE_VIDEO}" => {
		${HEADER_VIDEO_FRAGMENT}
	}
`;

export type PageHeadFragment = TextHeadingFragment | HeaderVideoFragment;
